/* eslint-disable */
import React from 'react';
import {
  BiHome,
  BiUser,
  BiBriefcase,
  BiLock,
  BiUserCircle,
  BiBasket,
  BiPencil,
  BiNotepad,
  BiBox,
  BiListCheck,
  BiListPlus,
  BiStore,
  BiCircle,
  BiMove,
  BiCut,
  BiRuler,
  BiGridAlt,
  BiUpload
} from 'react-icons/bi';
import {AiOutlineBars, AiOutlineFire, AiFillFire, AiOutlineEye, AiOutlineDropbox, AiOutlineInbox, AiOutlineMinusCircle, AiOutlineCheck} from 'react-icons/ai';
import { RiFocus2Line } from 'react-icons/ri';
import { BsReception0, BsSnow, BsAlignStart, BsFileEarmarkSpreadsheet } from 'react-icons/bs';
import { HiOutlineThumbUp, HiFolder, HiOutlineServer } from 'react-icons/hi';
import { FaBox, FaCreativeCommonsSamplingPlus, FaFileUpload, FaFire, FaTags } from 'react-icons/fa';
import { MdCameraRoll, MdContentCut, MdDocumentScanner, MdOutlineEventNote } from 'react-icons/md';
import { IoRemoveOutline, IoReorderFourOutline } from 'react-icons/io5';
import { PiCardholderDuotone, PiNotebookDuotone, PiThermometerHot } from 'react-icons/pi';


const routesConfig = [
  {
    id: 'app',
    title: 'Dashboard',
    messageId: 'dashboard',
    type: 'group',
    children: [
      {
        id: 'home',
        title: 'Home',
        messageId: 'sidebar.dashboard.home',
        type: 'item',
        icon: <BiHome />,
        path: '/dashboard/home',
      },
      {
        id: 'approval',
        title: 'Approval',
        messageId: 'sidebar.dashboard.approval',
        type: 'item',
        icon: <HiOutlineThumbUp />,
        path: '/approval',
      },
      {
        id: 'file-manager',
        title: 'File Manager',
        messageId: 'sidebar.dashboard.filemanager',
        moduleName: 'FileManager',
        type: 'item',
        icon: <HiFolder />,
        path: '/file-manager',
      },
    ],
  },
  {
    id: 'app',
    title: 'Security',
    messageId: 'security',
    type: 'group',
    children: [
      {
        id: 'manageuser',
        moduleName: 'User',
        title: 'Manage User',
        messageId: 'sidebar.security.manage-user',
        type: 'item',
        icon: <BiUser />,
        path: '/security/manageuser',
      },
      {
        id: 'managerole',
        moduleName: 'Role',
        title: 'Manage Role',
        messageId: 'sidebar.security.manage-role',
        type: 'item',
        icon: <BiBriefcase />,
        path: '/security/managerole',
      },
      {
        id: 'permission',
        moduleName: 'Authorization',
        title: 'Permission',
        messageId: 'sidebar.security.permission',
        type: 'item',
        icon: <BiLock />,
        path: '/security/permission',
      },
    ],
  },
  {
    id: 'app',
    title: 'Master',
    messageId: 'master',
    type: 'group',
    children: [
      {
        id: 'workorder',
        moduleName: 'WorkOrder',
        title: 'Work Order',
        messageId: 'sidebar.master.work-order',
        type: 'item',
        icon: <MdDocumentScanner />,
        path: '/master/workorder',
      },
      {
        id: 'vendor',
        moduleName: 'Vendor',
        title: 'Vendor',
        messageId: 'sidebar.master.vendor',
        type: 'item',
        icon: <BiBasket />,
        path: '/master/vendor',
      },
      {
        id: 'customer',
        moduleName: 'Customer',
        title: 'Customer',
        messageId: 'sidebar.master.customer',
        type: 'item',
        icon: <BiUserCircle />,
        path: '/master/customer',
      },
      {
        id: 'grade',
        moduleName: 'Grade',
        title: 'Grade',
        messageId: 'sidebar.master.grade',
        type: 'item',
        icon: <AiOutlineBars />,
        path: '/master/grade',
      },
      {
        id: 'itemmaster',
        moduleName: 'ItemMaster',
        title: 'Item Master',
        messageId: 'sidebar.master.item-master',
        type: 'item',
        icon: <BiGridAlt />,
        path: '/master/itemmaster',
      },
      {
        id: 'standard',
        moduleName: 'Standard',
        title: 'Standard',
        messageId: 'sidebar.master.standard',
        type: 'item',
        icon: <FaTags />,
        path: '/master/standard',
      },
      {
        id: 'equipment',
        moduleName: 'Equipment',
        title: 'Equipment',
        messageId: 'sidebar.master.standard',
        type: 'item',
        icon: <AiOutlineDropbox />,
        path: '/master/equipment',
      },
    ],
  },
  {
    id: 'app',
    title: 'Warehouse',
    messageId: 'warehouse',
    type: 'group',
    children: [
      {
        id: 'detaillist',
        moduleName: 'DetailList',
        title: 'Detail List',
        messageId: 'sidebar.warehouse.detail-list',
        type: 'item',
        icon: <BsFileEarmarkSpreadsheet />,
        path: '/warehouse/detaillist',
      },
      {
        id: 'deliveryorder',
        moduleName: 'DeliveryOrder',
        title: 'Raw Material Receive',
        messageId: 'sidebar.warehouse.delivery-order',
        type: 'item',
        icon: <BiNotepad />,
        path: '/warehouse/deliveryorder',
      },
      {
        id: 'storagegroup',
        title: 'Storage',
        messageId: 'sidebar.warehouse.storage',
        type: 'collapse',
        icon: <BiBox />,
        children: [
          {
            id: 'storage',
            moduleName: 'Storage',
            title: 'Store',
            messageId: 'sidebar.warehouse.storage.store',
            type: 'item',
            icon: <BiStore />,
            path: '/warehouse/storage',
          },
          {
            id: 'billet',
            moduleName: 'Billet',
            title: 'Stock',
            messageId: 'sidebar.warehouse.storage.billet',
            type: 'item',
            icon: <IoRemoveOutline />,
            path: '/warehouse/storage/billet',
          },
          {
            id: 'billetbundle',
            moduleName: 'BilletBundle',
            title: 'Bundles',
            messageId: 'sidebar.warehouse.storage.billet_bundle',
            type: 'item',
            icon: <IoReorderFourOutline />,
            path: '/warehouse/storage/billetbundle',
          },
        ],
      },
      {
        id: 'material-picking',
        moduleName: 'MaterialPicking',
        title: 'Material Reserve',
        messageId: 'sidebar.master.material-picking',
        type: 'item',
        icon: <BiNotepad />,
        path: '/warehouse/material-picking',
      },
      {
        id: 'movement',
        moduleName: 'Movement',
        title: 'Movement',
        messageId: 'sidebar.warehouse.movement',
        type: 'item',
        icon: <BiMove />,
        path: '/warehouse/movement',
      },
    ],
  },
  {
    id: 'app',
    title: 'QC',
    messageId: 'qc',
    type: 'group',
    children: [
      {
        id: 'receivinggroup',
        title: 'Receiving',
        messageId: 'sidebar.qc.receiving',
        type: 'collapse',
        icon: <MdOutlineEventNote />,
        children: [
          {
            id: 'receiving',
            moduleName: 'QCReceiving',
            title: 'Receiving',
            messageId: 'sidebar.qc.receiving',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/receiving',
          },
          {
            id: 'physicalchemicaltest',
            moduleName: 'PhysicalChemicalTestBillet',
            title: 'Chemical Test',
            messageId: 'sidebar.qc.physicalchemicaltest',
            type: 'item',
            icon: <BiUpload />,
            path: '/qc/physicalchemicaltest',
          },
        ]
      },
      {
        id: 'mtc',
        moduleName: 'MTC',
        title: 'MTC',
        messageId: 'sidebar.qc.mtc',
        type: 'item',
        icon: <FaFileUpload />,
        path: '/qc/mtc',
      },
      {
        id: 'ncr',
        moduleName: 'NCR',
        title: 'NCR',
        messageId: 'sidebar.qc.ncr',
        type: 'item',
        icon: <PiNotebookDuotone />,
        path: '/qc/ncr',
      },
      {
        id: 'circulation-card',
        title: 'Circulation Card',
        moduleName: 'CirculationCard',
        messageId: 'sidebar.qc.circulation-card',
        type: 'item',
        icon: <PiCardholderDuotone />,
        path: '/qc/circulation-card',
      },
      // {
      //   id: 'drift-recheck-form',
      //   title: 'Drift Re-Check Form',
      //   messageId: 'sidebar.qc.driftrecheckform',
      //   type: 'item',
      //   icon: <AiOutlineCheckSquare />,
      //   path: '/qc/driftrecheckform',
      // },
      {
        id: 'qcrollinggroup',
        title: 'Rolling',
        messageId: 'sidebar.qc.rolling',
        type: 'collapse',
        icon: <MdCameraRoll />,
        children: [
          {
            id: 'physicalchemicaltestrolling',
            title: 'Chemical Test',
            moduleName: 'ChemicalTestRolling',
            messageId: 'sidebar.qc.chemicaltest',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/physicalchemicaltestrolling',
          },
          {
            id: 'emi',
            moduleName: 'EMI',
            title: 'EMI',
            messageId: 'sidebar.qc.emi',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/emi',
          },
          {
            id: 'pipevisualinspection',
            title: 'Pipe Visual Inspection',
            moduleName: 'PipeVisualInspection',
            messageId: 'sidebar.qc.pipevisualinspection',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/pipevisualinspection',
          },
          {
            id: 'routing-rolling',
            moduleName: 'RoutingRolling',
            title: 'Routing Rolling Inspection',
            messageId: 'sidebar.qc.ut',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/routing-rolling',
          },
        ]
      },
      {
        id: 'qcupsetgroup',
        title: 'Upset',
        messageId: 'sidebar.qc.upset',
        type: 'collapse',
        icon: <FaCreativeCommonsSamplingPlus />,
        children: [
          {
            id: 'routing-upset',
            title: 'Upset Line',
            moduleName: 'RoutingUpset',
            messageId: 'sidebar.qc.ru',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/routing-upset',
          },
          {
            id: 'upset',
            title: 'Upset Grinding',
            moduleName: 'UpsetInspection',
            messageId: 'sidebar.qc.upset',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/upset',
          },
        ]
      },
      {
        id: 'qchtgroup',
        title: 'Heat Treatment',
        messageId: 'sidebar.qc.ht',
        type: 'collapse',
        icon: <FaFire />,
        children: [
          {
            id: 'physicalchemicaltestheattreatment',
            title: 'Chemical Test',
            moduleName: 'ChemicalTestHT',
            messageId: 'sidebar.qc.chemicaltest',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/physicalchemicaltestheattreatment',
          },
          {
            id: 'visual-inspection',
            title: 'Visual Inspection',
            moduleName: 'VisualInspection',
            messageId: 'sidebar.qc.visualinspection',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/visual-inspection',
          },
          {
            id: 'mpi',
            title: 'MPI',
            moduleName: 'MPI',
            messageId: 'sidebar.qc.mpi',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/mpi',
          },
          {
            id: 'ut',
            moduleName: 'UT',
            title: 'UT',
            messageId: 'sidebar.qc.ut',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/ut',
          },
        ]
      },
      {
        id: 'qcpackinggroup',
        title: 'Packing',
        messageId: 'sidebar.qc.thdpkg',
        type: 'collapse',
        icon: <FaBox />,
        children: [
          {
            id: 'couplinginspection',
            title: 'Coupling Inspection',
            moduleName: 'CouplingInspection',
            messageId: 'sidebar.qc.couplinginspection',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/couplinginspection',
          },
          {
            id: 'threadingline',
            title: 'Threading Line Inspection',
            moduleName: 'ThreadingLine',
            messageId: 'sidebar.qc.threadingline',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/threading-line',
          },
          {
            id: 'finalinspction',
            title: 'Final Inspection',
            moduleName: 'FinalInspection',
            messageId: 'sidebar.qc.finalinspection',
            type: 'item',
            icon: <AiOutlineCheck />,
            path: '/qc/finalinspection',
          },
          {
            id: 'outgoinginspection',
            title: 'Outgoing Inspection',
            moduleName: 'Outgoing',
            messageId: 'sidebar.qc.outgoinginspection',
            type: 'item',
            icon: <BiListCheck />,
            path: '/qc/outgoinginspection',
          },
        ]
      }
    ]
  },
  {
    id: 'app',
    title: 'Production',
    messageId: 'production',
    type: 'group',
    children: [
      {
        id: 'rolling',
        title: 'Rolling',
        messageId: 'sidebar.productionrolling',
        type: 'collapse',
        icon: <MdCameraRoll />,
        children: [
          {
            id: 'billetcutting',
            moduleName: 'Cutting',
            title: 'Billet Cutting',
            messageId: 'sidebar.productionrolling.billet-cutting',
            type: 'item',
            icon: <BiCut />,
            path: '/production/billetcutting',
          },
          {
            id: 'billet-heating',
            moduleName: 'Heating',
            title: 'Billet Heating',
            messageId: 'sidebar.productionrolling.bilet-heating',
            type: 'collapse',
            icon: <AiOutlineFire />,
            children: [
              {
                id: 'furnace-heating',
                title: 'Furnace',
                messageId: 'sidebar.productionrolling.billet-heating.furnace.heating',
                queryParams: '?type=heating',
                type: 'item',
                icon: <AiFillFire />,
                path: '/production/billetheating/furnace?type=heating',
                isChildNextLevel: true,
              },
              {
                id: 'furnace-reheating',
                title: 'Furnace Reheating',
                messageId: 'sidebar.productionrolling.billet-heating.furnace.reheating',
                queryParams: '?type=reheating',
                type: 'item',
                icon: <AiOutlineFire />,
                path: '/production/billetheating/furnace?type=reheating',
                isChildNextLevel: true,
              },
              {
                id: 'inspection',
                title: 'Inspection',
                messageId: 'sidebar.productionrolling.billet-heating.inspection',
                type: 'item',
                icon: <AiOutlineEye />,
                path: '/production/billetheating/inspection',
                isChildNextLevel: true,
              },
            ],
          },
          {
            id: 'piercing',
            moduleName: 'Piercing',
            title: 'Piercing',
            messageId: 'sidebar.productionrolling.piercing',
            type: 'item',
            icon: <HiOutlineServer />,
            path: '/production/piercing',
          },
          {
            id: 'rolling',
            moduleName: 'Rolling',
            title: 'AR Rolling',
            messageId: 'sidebar.productionrolling.rolling',
            type: 'item',
            icon: <RiFocus2Line />,
            path: '/production/rolling',
          },
          {
            id: 'sizing',
            moduleName: 'Sizing',
            title: 'Sizing',
            messageId: 'sidebar.productionrolling.sizing',
            type: 'item',
            icon: <BiRuler />,
            path: '/production/sizing',
          },
          {
            id: 'coolingbed',
            moduleName: 'CoolingBed',
            title: 'Cooling Bed',
            messageId: 'sidebar.productionrolling.cooling-bed',
            type: 'item',
            icon: <BsSnow />,
            path: '/production/coolingbed',
          },
          {
            id: 'straightening',
            moduleName: 'Straightening',
            title: 'Straightening',
            messageId: 'sidebar.productionrolling.straightening',
            type: 'item',
            icon: <BsReception0 />,
            path: '/production/straightening',
          },
          {
            id: 'pipe-end',
            moduleName: 'PipeEnd',
            title: 'Pipe End Cutting',
            messageId: 'sidebar.productionrolling.pipe-end',
            type: 'collapse',
            icon: <MdContentCut />,
            children: [
              {
                id: 'pipe-end-a',
                title: 'Pipe End Cutting A',
                messageId: 'sidebar.productionrolling.pipe-end.a',
                queryParams: '?type=A',
                type: 'item',
                icon: <MdContentCut />,
                path: '/production/pipeend?type=A',
                isChildNextLevel: true,
              },
              {
                id: 'pipe-end-b',
                title: 'Pipe End Cutting B',
                messageId: 'sidebar.productionrolling.pipe-end.b',
                queryParams: '?type=B',
                type: 'item',
                icon: <MdContentCut />,
                path: '/production/pipeend?type=B',
                isChildNextLevel: true,
              },
            ],
          },
          {
            id: 'fulllengthdrift',
            moduleName: 'FullLengthDrift',
            title: 'Full Length Drift',
            messageId: 'sidebar.productionrolling.full-length-drift',
            type: 'item',
            icon: <BsAlignStart />,
            path: '/production/fulllengthdrift',
          },
        ]
      },
      {
        id: 'upset',
        title: 'Upset',
        messageId: 'sidebar.productionupset',
        type: 'collapse',
        icon: <FaCreativeCommonsSamplingPlus />,
        children: [
          {
            id: 'upsetting',
            title: 'Upsetting',
            moduleName: 'Upsetting',
            messageId: 'sidebar.upset.upsetting',
            type: 'item',
            icon: <FaCreativeCommonsSamplingPlus />,
            path: '/production/upsetting',
          },
        ]
      },
      {
        id: 'ht',
        title: 'Heat Treatment',
        messageId: 'sidebar.ht',
        type: 'collapse',
        icon: <FaFire />,
        children: [
          {
            id: 'heattreatment',
            title: 'Heat Treatment',
            moduleName: 'HeatTreatment',
            messageId: 'sidebar.heattreatment.heattreatment',
            type: 'item',
            icon: <FaFire />,
            path: '/production/heattreatment',
          },
          {
            id: 'hotstraightening',
            title: 'Hot Straightening',
            moduleName: 'HotStraightening',
            messageId: 'sidebar.heattreatment.hotstraightening',
            type: 'item',
            icon: <PiThermometerHot />,
            path: '/production/hotstraightening',
          },
        ]
      },
      {
        id: 'packing',
        title: 'Packing',
        messageId: 'sidebar.thdpkg',
        type: 'collapse',
        icon: <FaBox />,
        children: [
          {
            id: 'hydrotest',
            title: 'Hydrotest',
            moduleName: 'Hydrotest',
            messageId: 'sidebar.qc.packing.hydrotest',
            type: 'item',
            icon: <BiListCheck />,
            path: '/production/hydrotest',
          },
          {
            id: 'fulllengthdriftpacking',
            title: 'Full Length Drift',
            moduleName: 'FullLengthDriftPacking',
            messageId: 'sidebar.packing.FullLengthDriftPacking',
            type: 'item',
            icon: <AiOutlineMinusCircle />,
            path: '/production/full-length-drift-packing',
          },
          {
            id: 'coatingmarkingpacking',
            title: 'Coating Marking Packing',
            moduleName: 'CMP',
            messageId: 'sidebar.packing.cmp',
            type: 'item',
            icon: <AiOutlineInbox />,
            path: '/production/coating-marking-packing',
          },
          {
            id: 'Tally',
            title: 'Tally',
            moduleName: 'Tally',
            messageId: 'sidebar.qc.tally',
            type: 'item',
            icon: <BiListCheck />,
            path: '/production/tally',
          },
        ]
      }
    ]
  },
];
export default routesConfig;
